/* --------------------------------------------------------------------------------------------------- */
/* FLASH MESSAGE -------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */

.flash-messages {
	position:fixed;
	left:12px; bottom:12px;
	z-index:99998;
	line-height:1.3;
	color:#FFF;
	font-size:14px;

	@media screen and (max-width:768px)
	{
		right:12px;
	}

	&.is-all-dismissed {
		display:none;
	}
}

	.flash-message {
		@include animate;
		@include flex-row;
		justify-content:space-between;
		max-width:360px;

		&:not(:first-child) {
			margin-top:6px;
		}

		&.flash_error,
		&.flash_invalid {
			background:map-get($colors, _error);

			.flash-message_close {
				background:darken(map-get($colors, _error), 9%);
			}
		}
		&.flash_alert {
			background:map-get($colors, _alert);

			.flash-message_close {
				background:darken(map-get($colors, _alert), 9%);
			}
		}
		&.flash_valid {
			background:map-get($colors, _valid);

			.flash-message_close {
				background:darken(map-get($colors, _valid), 9%);
			}
		}
		&.flash_update {
			background:map-get($colors, _update);

			.flash-message_close {
				background:darken(map-get($colors, _update), 9%);
			}
		}

		&.is-dismissed {
			opacity:0;
			transform:translateX(12px);
			visibility:hidden;
		}
	}

	.flash-message_close {
		@include flex-column;
		justify-content:center;
		align-items:center;
		flex-shrink:0;
		width:48px;

		svg {
			@include animate;
			display:block;
			width:14px; height:14px;
			fill:#FFF;
			opacity:.48;
		}

		@media screen and (min-width:769px)
		{
			&:hover svg,
			&:focus svg {
				opacity:1;
			}
		}
	}
