/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (PRIMARY) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * First Level
 */

.nav-primary {

	@media screen and (max-width:768px)
	{
		@include animate;
		@include flex-column;
		justify-content:center;
		flex:1 1 auto;
		margin-left:80px; padding:48px;
		background:#FFF;
		transform:translateX(100%);
	}
}
	.nav-primary_list {
		@include flex-row;
		list-style:none;

		@media screen and (max-width:768px)
		{
			flex-direction:column;
		}
	}
		.nav-primary_item {
			line-height:map-get($line_heights, _small);
			font-size:map-get($font_sizes, _small);

			@media screen and (min-width:769px)
			{
				&:not(:last-child) {
					padding-right:32px;
				}
			}
			@media screen and (max-width:768px)
			{
				line-height:map-get($line_heights, _base);
				font-size:22px;

				&:not(:first-child) {
					padding-top:24px;
				}
			}
		}
			.nav-primary_link {
				@include animate;

				&.is-current {
					cursor:default;
				}

				@media screen and (min-width:769px)
				{
					color:#FFF;

					&.is-current,
					&:not(.is-current):hover,
					&:not(.is-current):focus {
						color:map-get($colors, _02-03);
					}
				}
				@media screen and (max-width:768px)
				{
					color:map-get($colors, _01-01);

					&.is-current {
						color:map-get($colors, _02-02);
					}
				}
			}

/*
 * Sidebar Nav
 */

.nav-side {
	margin-top: 18px;
	h4 {
		width: 200px;
		color: map-get($colors, _02-01);
		font-weight: 500;
		border-bottom: 2px solid map-get($colors, _01-03);
		padding-bottom: 12px;
	}
	.nav-side_list {
		list-style-type: none;
		margin-top: 12px;
		display: inline-block;
	}
	.nav-side_item {
		padding: 6px 24px 6px 0;
		.nav-side_link {
			color: map-get($colors, _02-01);
			&.is-current {
				color: map-get($colors, _01-01);
				font-weight: 500;
			}
		}
	}
	@media screen and (max-width:768px)
	{
		display: none;
	}
}

/*
 * Modal (Mobile)
 */

.modal-nav {
	@include flex-column;
	background:rgba(#000, .88);

	&.open {

		.nav-primary {
			transform:translateX(0%);
		}
		.modal-nav_close {
			transform:translateX(0%);
		}
	}
}
	.modal-nav_content {
		@include flex-column;
		flex:1 1 auto;
	}
		.modal-nav_close {
			@include animate;
			@include flex-column;
			justify-content:center;
			align-items:center;
			position:fixed;
			top:0; left:0;
			z-index:2;
			width:80px; height:80px;
			background:map-get($colors, _02-01);
			transform:translateX(-100%);

			svg {
				display:block;
				width:18px; height:18px;
				fill:#FFF;
			}
		}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (FOOTER) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * First Level
 */

 .nav-footer {
	@include flex-column;
	align-items:center;
	padding-bottom:24px;
}
	.nav-footer_list {
		@include flex-row;
		list-style:none;
	}
		.nav-footer_item {

			&:not(:last-child) {
				padding-right:24px;
			}
		}
			.nav-footer_link {
				@include animate;
				color:map-get($colors, _02-03);

				&.is-current {
					color:#FFF;
					cursor:default;
				}

				@media screen and (min-width:769px)
				{
					&:not(.is-current):hover,
					&:not(.is-current):focus {
						color:#FFF;
					}
				}
			}
