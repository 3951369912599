/* ------------------------------------------------------------------------------------------------------------------------ */
/* SERVICES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Services (Navigation)
 */

.nav-services {
	padding-top:48px;

	@media screen and (max-width:768px)
	{
		margin-top:-6px; padding:24px 24px 0;
	}
}
	.nav-services_list {
		@include flex-row;
		list-style:none;

		@media screen and (min-width:769px)
		{
			justify-content:center;
		}
		@media screen and (max-width:768px)
		{
			flex-direction:column;
		}
	}
		.nav-services_item {

			@media screen and (min-width:769px)
			{
				&:not(:only-child) {
					padding:0 6px;
				}
				&:first-child {
					padding-left:0;
				}
				&:last-child {
					padding-right:0;
				}
			}
			@media screen and (max-width:768px)
			{
				padding-top:6px;
			}
		}
			.nav-services_btn {
				@include animate;
				padding:16px 32px;
				color:map-get($colors, _01-01);
				font-weight:500;
				border:2px solid map-get($colors, _01-04);

				@media screen and (min-width:769px)
				{
					&:not(:disabled):hover,
					&:not(:disabled):focus {
						color:#FFF;
						background:map-get($colors, _02-02);
						border:2px solid map-get($colors, _02-02);
					}
				}
				@media screen and (max-width:768px)
				{
					padding:14px 24px;
					width:100%;
				}
			}
