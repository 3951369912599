/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin heading($line_height:map-get($line_heights, _base), $line_height_m:map-get($line_heights, _small), $font_size:map-get($font_sizes, _base), $font_size_m:map-get($font_sizes, _small)) {
	display:block;
	line-height:$line_height;
	font-size:$font_size;
	font-family:map-get($font_families, _01);

	@media screen and (max-width:768px)
	{
		line-height:$line_height_m;
		font-size:$font_size_m;
	}
}

h1, h2, h3, h4, h5, h6 {
	margin:0;
	font-weight:500;
}
h1, .h1 {
	@include heading(1.2, 1.4, 48px, 32px);
}
h2, .h2 {
	@include heading(1.3, 1.5, 32px, 24px);
}
h3, .h3 {
	@include heading(1.4, 1.5, 26px, 22px);
}
h4, .h4 {
	@include heading(1.5, map-get($line_heights, _base), 24px, map-get($font_sizes, _base));
}
h5, .h5 {
	@include heading;
}
h6, .h6 {
	@include heading(map-get($line_heights, _small), map-get($line_heights, _small), map-get($font_sizes, _small), map-get($font_sizes, _small));
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* HR LINES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

hr {
	height:0;
	overflow:visible;
	box-sizing:content-box;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANCHORS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

a {
	color:map-get($colors, _01-01);
	text-decoration:none;
	text-decoration-skip:objects;
	background-color:transparent;

	&:active,
	&:hover {
		outline-width:0;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT BOLD -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

b,
strong {
	font-weight:inherit;
	font-weight:bold;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT ITALIC ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

i,
em,
dfn {
	font-style:italic;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT HIGHLIGHTED ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

mark {
	color:#333;
	background:none;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT SMALL ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

small,
.small {
	font-size:map-get($font_sizes, _small);
}

sub,
sup {
	position:relative;
	line-height:0;
	font-size:75%;
	vertical-align:baseline;
}
sub {
	bottom:-0.25em;
}
sup {
	top:-0.5em;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* IMAGES ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

figure {
	display:block;
	margin:0;

	figcaption {
		display:block;
	}
}

img {
	width:auto\9; height:auto;
	vertical-align:middle;
	border:0;
	-ms-interpolation-mode:bicubic;

	&.align_left {
		float:left;
	}
	&.align_right {
		float:right;
	}
}

svg:not(:root) {
	overflow:hidden;
}
