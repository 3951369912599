/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.typography {
	color:map-get($colors, _01-02);


	/*
	 * Headings
	 */

	h1, h2, h3, h4, h5, h6 {
		color:map-get($colors, _02-01);
		font-weight:500;
	}


	/*
	 * Strong
	 */

	strong {
		color:map-get($colors, _01-01);
		font-weight:500;
	}


	/*
	 * Lists
	 */

	ul {
		list-style:none;

		li {
			@include flex-row;

			&:not(:last-child) {
				padding-bottom:1em;
			}

			&:before {
				content:'•';
				flex-shrink:0;
				padding-right:12px;
				// color:map-get($colors, _01-01);
				color: #605F6B;
				font-weight:500;
			}
		}
	}

	ol {
		list-style:none;
		counter-reset:ol-counter;

		li {
			@include flex-row;
			counter-increment:ol-counter;

			&:not(:last-child) {
				padding-bottom:1em;
			}

			&:before {
				content:counter(ol-counter);
				flex-shrink:0;
				padding-right:24px;
				color:map-get($colors, _01-01);
				font-weight:500;
			}
		}
	}


	/*
	 * Anchors
	 */

	a:not(.btn):not(.link) {
		@include animate;
		text-decoration:underline;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:map-get($colors, _02-02);
				text-decoration:none;
			}
		}
	}


	/*
	 * Buttons
	 */

	a.btn {
		display:inline-block;

		@media screen and (max-width:768px)
		{
			width:100%;
			text-align:center;
		}

		& ~ a.btn {

			@media screen and (min-width:769px)
			{
				margin-left:12px;
			}
			@media screen and (max-width:768px)
			{
				margin-top:12px;
			}
		}
	}


	/*
	 * Spacing (Normal)
	 */

	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol {

		&:not(:first-child) {
			padding-top:24px;

			@media screen and (max-width:768px)
			{
				padding-top:18px;
			}
		}
	}


	/*
	 * Spacing (Shorter)
	 */

	&.half-spacing {

		h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol {

			&:not(:first-child) {

				@media screen and (min-width:769px)
				{
					padding-top:18px;
				}
			}
		}
	}


	/*
	 * Light Tipography
	 */

	&.typo-light {
		color:#FFF;

		h1, h2, h3, h4, h5, h6, strong {
			color:#FFF;
		}

		a:not(.btn):not(.link) {
			color:#FFF;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:#FFF;
				}
			}
		}
	}

	img {
		max-width:100%;

		@media screen and (max-width:768px)
		{
			width:100%;
		}

	}
}
