/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.btns-wrapper {

	&.direction-column {
		@include flex-column;

		.btn:not(:last-child) {
	        margin-bottom:12px;
	    }
	}

	&.direction-row {
		@include flex-row;

		@media screen and (max-width:768px)
		{
			flex-direction:column;

			.btn {
				text-align:center;
			}
		}

        &.align-center {
            justify-content:center;
        }
        &.align-right {
            justify-content:flex-end;
        }

		.btn:not(:last-child) {

			@media screen and (min-width:769px)
			{
	        	margin-right:12px;
			}
			@media screen and (max-width:768px)
			{
				margin-bottom:12px;
			}
	    }
	}
}

	.btn {
		@include animate;
		display:inline-block;
		font-weight:500;
		box-sizing:border-box;

		.btn-icon,
		svg {
			@include animate;
		}

		&:disabled {
			cursor:default;
		}
	}


/*
 * Btn (01)
 */

.btn-01 {
	padding:16px 32px;
	line-height:map-get($line_heights, _small);
	font-size:map-get($font_sizes, _small);
	text-transform:uppercase;

	@media screen and (max-width:768px)
	{
		padding:14px 24px;
	}

	&.btn_style_02-01,
	&.btn_style_02-02 {
		color:#FFF;

		&.btn_has-icon svg {
			fill:#FFF;
		}
	}

	&.btn_style_02-01 {
		background:map-get($colors, _02-01) !important;

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				background:map-get($colors, _02-02) !important;
			}
		}
	}

	&.btn_style_02-02 {
		background:map-get($colors, _02-02) !important;

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				background:map-get($colors, _02-01) !important;
			}
		}
	}

	&.btn_style_white {
		color:map-get($colors, _02-01);
		background:#FFF !important;

		&.btn_has-icon svg {
			fill:map-get($colors, _02-01);
		}

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
                color:#FFF;
				background:map-get($colors, _02-04) !important;

                svg {
                    fill:#FFF;
                }
			}
		}
	}
}


/*
 * Btn (02)
 */

.btn-02 {

	&.btn_style_02-02 {
		color:map-get($colors, _02-02) !important;

		&.btn_has-icon svg {
			fill:map-get($colors, _02-02);
		}

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				color:map-get($colors, _02-01) !important;

				svg {
                    fill:map-get($colors, _02-01);
				}

				&.btn_has-icon.icon-right .btn-icon {
					transform:translateX(6px);
				}
			}
		}
	}
}


/*
 * Btn (Has Icon)
 */

.btn_has-icon {
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	align-items:center;

	svg {
		display:block;
		flex-shrink:0;
		width:16px; height:16px;
	}

	.btn-icon {
		flex-shrink:0;
	}
	.btn-label {

		@media screen and (max-width:768px)
		{
			flex:1 1 auto;
		}
	}

	// Icon (Left)
	&:not(.icon-right) .btn-icon {
		margin-right:12px;

		@media screen and (max-width:768px)
		{
			margin-right:9px;
		}
	}

	// Icon (Right)
	&.icon-right .btn-icon {
		order:2;
		margin-left:12px;

		@media screen and (max-width:768px)
		{
			margin-left:9px;
		}
	}

	// Icon (Round)
	&.icon-round .btn-icon {
		@include flex-column;
		justify-content:center;
		align-items:center;
		width:48px; height:48px;
		border-radius:100%;
	}

	// Icon (Right-End)
	&.icon-right-end {
		justify-content:space-between;
	}

	// Icon (Rotate -90deg)
	&.icon-minus-90-deg svg {
		transform:rotate(-90deg);
	}

	// Icon (Rotate 180deg)
	&.icon-180-deg svg {
		transform:rotate(180deg);
	}
}


/*
 * Btn (Round)
 */

.btn-round {
	display:flex;
	flex-direction:column;
	flex-wrap:nowrap;
	justify-content:center;
	align-items:center;
	width:48px; height:48px;
	border-radius:100%;
	overflow:hidden;

	svg {
		display:block;
		flex-shrink:0;
		width:18px; height:18px;
	}
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (PREV / NEXT) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.btn-prev,
.btn-next {
	@include animate;
    @include flex-column;
    @include absolute(2);
    justify-content:center;
    align-items:center;
	width:48px; height:128px;
	background:map-get($colors, _01-04) !important;

	svg {
		@include animate;
        width:18px; height:18px;
		fill:map-get($colors, _02-01);

		@media screen and (max-width:768px)
		{
			width:16px !important; height:16px !important;
		}
	}

	&.swiper-button-disabled {
		cursor:default;

		svg {
			fill:rgba(#000, .12);
		}
	}

	@media screen and (min-width:769px)
	{
		top:50%;
		transform:translateY(-50%);

		&:not(.swiper-button-disabled):hover svg,
		&:not(.swiper-button-disabled):focus svg {
            fill:map-get($colors, _02-02);
		}
	}
	@media screen and (max-width:768px)
	{
		top:48px;
		width:52px; height:52px;
	}
}
.btn-prev {
	left:0;

	svg {
		transform:rotate(-180deg);
	}
}
.btn-next {
	right:0;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (MODAL CLOSE) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.modal_btn-close {
	position:fixed;
	top:48px; right:48px;
	z-index:9;

	svg {
		@include animate;
	}

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			transform:rotate(180deg);
		}
	}
}
