/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin animate($speed: 300ms, $ease: ease-in-out) {
	transition-duration:$speed;
	transition-timing-function:$ease;
}
.animate { @include animate; }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* PALETTE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@each $key, $color in $colors {

	// Colors
    .color#{$key} {
    	color:#{$color} !important;
    }
    a.color#{$key},
    button.color#{$key} {
    	@include animate;

    	@media screen and (min-width:769px)
    	{
    		&:not(:disabled):hover,
    		&:not(:disabled):focus {
    			color:#{$color} !important;
    			cursor:pointer;
    		}
    	}
    }

	// Backgrounds
	.bg#{$key} {
		background:#{$color} !important;
	}
	a.bg#{$key},
	button.bg#{$key} {
		@include animate;

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				background:darken($color, 9%) !important;
				cursor:pointer;
			}
		}
	}

	// Borders
	.border#{$key} {
		border-color:#{$color} !important;
	}
	a.border#{$key},
	button.border#{$key} {
		@include animate;

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				border-color:darken($color, 9%) !important;
				cursor:pointer;
			}
		}
	}

	// Fills
	.fill#{$key},
	.fill#{$key} svg {
		fill:#{$color} !important;
	}

}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* DISPLAY FLEX ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin flex {
	display:flex;
	flex-wrap:nowrap;
}
@mixin flex-row {
	@include flex;
	flex-direction:row;
}
@mixin flex-column {
	@include flex;
	flex-direction:column;
}

.flex-row { @include flex-row; }
.flex-column { @include flex-column; }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@each $key, $width in $widths {

	.w#{$key} {
		max-width:$width;
	}
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.bleed_24 { padding:24px; }
.bleed_48 { padding:48px; }
.bleed_96 { padding:96px; }

.bleed_top_24 { padding-top:24px; }
.bleed_top_48 { padding-top:48px; }
.bleed_top_96 { padding-top:96px; }

.bleed_bottom_24 { padding-bottom:24px; }
.bleed_bottom_48 { padding-bottom:48px; }
.bleed_bottom_96 { padding-bottom:96px; }

@media screen and (max-width:1220px) and (min-width:769px)
{
	.bleed_96 { padding:48px; }
	.bleed_top_96 { padding-top:48px; }
	.bleed_bottom_96 { padding-bottom:48px; }
}
@media screen and (max-width:768px)
{
	.bleed_48,
	.bleed_96 { padding:24px; }

	.bleed_top_48,
	.bleed_top_96 { padding-top:24px; }

	.bleed_bottom_48,
	.bleed_bottom_96 { padding-bottom:24px; }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESETS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

// Margins
.reset_margin { margin:0 !important; }
.reset_margin_top { margin-top:0 !important; }
.reset_margin_right { margin-right:0 !important; }
.reset_margin_bottom { margin-bottom:0 !important; }
.reset_margin_left { margin-left:0 !important; }

// Paddings
.reset_padding { padding:0 !important; }
.reset_padding_top { padding-top:0 !important; }
.reset_padding_right { padding-right:0 !important; }
.reset_padding_bottom { padding-bottom:0 !important; }
.reset_padding_left { padding-left:0 !important; }

// Borders
.reset_border { border:0 solid !important; }
.reset_border_top { border-top:0 solid !important; }
.reset_border_right { border-right:0 solid !important; }
.reset_border_bottom { border-bottom:0 solid !important; }
.reset_border_left { border-left:0 solid !important; }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin relative($zindex: 1) {
	position:relative;
	z-index:$zindex;
}
.relative { @include relative; }

@mixin absolute($zindex: 1) {
	position:absolute;
	z-index:$zindex;
}
.absolute { @include absolute; }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT ALIGNMENT --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin text-left { text-align:left; }
.text-left { @include text-left; }

@mixin text-right { text-align:right; }
.text-right { @include text-right; }

@mixin text-center { text-align:center; }
.text-center { @include text-center; }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin full-size($zindex: 1) {
	position:absolute;
	top:0; left:0; right:0; bottom:0;
	z-index:$zindex;
}
.full-size { @include full-size; }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin full-width {
	display:block;
	width:100%; height:auto;
}
.full-width { @include full-width; }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* ASPECT RATIO ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin aspect-ratio($aspect-ratio)
{
	content:"";
	display:block;
	padding-top:calc((#{$aspect-ratio}) * 100%);
}

@mixin figure_aspect-ratio($aspect-ratio)
{
	content:"";
	display:block;
	padding-top:calc((#{$aspect-ratio}) * 100%);
	width:100%; height:0;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.hide-desktop {

	@media screen and (min-width:769px)
	{
		display:none !important;
	}
}

.hide-mobile {

	@media screen and (max-width:768px)
	{
		display:none !important;
	}
}
