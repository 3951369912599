/* --------------------------------------------------------------------------------------------------- */
/* VIDEO --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */


/*
 * Video
 */

.wrapper-video {
	@include relative;
	overflow:hidden;

	&:before {
		@include figure_aspect-ratio('9 / 16');
	}

	video,
	iframe {
		@include full-size;
		width:100%; height:100%;
	}
}


/*
 * Placeholder
 */

.video-placeholder_wrapper,
.video-placeholder {
	@include full-size(2);
}
.video-placeholder_wrapper {
	@include animate;
	width:100%;
	background:#000 !important;
	overflow:hidden;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover .video-placeholder,
		&:not(:disabled):focus .video-placeholder {
			opacity:.32 !important;
			transform:scale(1.05);
		}
	}

	&.open {
		opacity:0;
		visibility:hidden;
	}
}
	.video-placeholder {
		@include animate;
		@include full-size;
		@include full-width;
		position:absolute !important;
		height:100% !important;
		opacity:.48 !important;
		object-fit:cover; font-family:'object-fit:cover;';
	}


/*
 * Play Icon
 */

.video-play {
	@include flex-column;
	@include absolute(3);
	justify-content:center;
	align-items:center;
	top:50%; left:50%;
	width:64px; height:48px;
	background:map-get($colors, _01-01);
	border-radius:6px;
	transform:translate(-50%, -50%);

	svg {
		display:block;
		width:24px; height:24px;
		fill:#FFF;
	}
}
