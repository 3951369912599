/* ------------------------------------------------------------------------------------------------------------------------ */
/* CARD ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.card {
    @include flex-column;
	flex:1 1 auto;
}

    .card-thumb {
        @include relative;

        &:before {
    		@include relative;
    		@include figure_aspect-ratio('3 / 4');
    	}
    }
        .card-thumb_image-wrapper,
        .card-thumb_image {
            @include full-size;
        }
        .card-thumb_image-wrapper {
            overflow:hidden;
        }
            .card-thumb_image {
                position:absolute !important;
                height:100% !important;
                object-fit:contain; font-family:'object-fit:contain;';
                // filter:grayscale(100%);
            }
        .card-thumb_link {
            @include full-size(2);

            @media screen and (min-width:769px)
            {
                &:hover + .card-thumb_image-wrapper .card-thumb_image ,
                &:focus + .card-thumb_image-wrapper .card-thumb_image {
                    transform:scale(1.05);
                }
            }
        }

    .card-content {
        @include flex-column;
        flex:1 1 auto;
        padding:32px 24px 0 0;

        @media screen and (max-width:768px)
        {
            padding:24px 0 0 0;
        }
    }

        .card-heading {
            font-family:map-get($font_families, _01);
            font-size:24px;
            font-weight:500;

            @media screen and (max-width:768px)
            {
                font-size:map-get($font_sizes, _base);
            }
        }
            .card-heading_link {
                @include animate;
                color:map-get($colors, _01-01);

                @media screen and (min-width:769px)
                {
                    &:hover,
                    &:focus {
                        color:map-get($colors, _02-01);
                    }
                }
            }

        .card-excerpt {
            padding-top:18px;
            line-height:map-get($line_heights, _small);
            font-size:map-get($font_sizes, _small);

            @media screen and (max-width:768px)
            {
                padding-top:12px;
            }
        }

        .card-link_wrapper {
            padding-top:24px;

            @media screen and (max-width:768px)
            {
                flex-direction:row !important;
                padding-top:18px;
            }
        }
