/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: GENERIC ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_generic {

	.pb-block_content > .grid {

        @media screen and (max-width:768px)
        {
            margin-top:-24px;
        }

        & > .column {

			.typography ul li {
				padding-bottom: 0.5em;
			}

			.typography h3 a {
				text-decoration: none;
			}

    		@media screen and (max-width:768px)
    		{
                padding-top:24px;

    			&.m_order-1 {
    				order:1;
    			}
    			&.m_order-2 {
    				order:2;
    			}
    			&.m_order-3 {
    				order:3;
    			}

				.typography p {
					padding-top: 9px;
				}
    		}
        }
	}

	.has_border {

		@media screen and (min-width:769px)
		{
			display:flex;
			flex-direction:column;
			flex:1 1 auto;
			padding:0 32px;

			&.border_left {
				border-left:4px solid map-get($colors, _02-01);
			}
		}
	}

	.media-image {
		// filter:grayscale(100%);
	}
}
