/* ------------------------------------------------------------------------------------------------------------------------ */
/* TIMELINE --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.timeline {

	@media screen and (max-width:768px)
	{
		padding:24px 0;
	}
}

	.timeline-grid {
		@include relative;

		&:before {
			@include absolute(-1);
			content:"";
			top:0; left:50%; bottom:0;
			width:6px;
			transform:translateX(-50%);
			background:map-get($colors, _01-04);
		}

		@media screen and (max-width:768px)
		{
			margin-top:0 !important;

			&:not(:first-child) {
				padding-top:48px;
			}

			& > .timeline-column {
				padding-top:0 !important;
			}
		}
	}
		.timeline-column {

			@media screen and (min-width:1221px)
			{
				&:not(:first-child) {
					margin-top:192px;
				}

				&:nth-child(odd) {

					.timeline-item_heading {

						&:before,
						&:after {
							left:-99px; right:auto;
						}
					}
				}
			}
			@media screen and (max-width:1220px) and (min-width:769px)
			{
				&:not(:first-child) {
					margin-top:96px;
				}

				&:nth-child(odd) {

					.timeline-item_heading {

						&:before,
						&:after {
							left:-83px; right:auto;
						}
					}
				}
			}
		}
			.timeline-item {
				line-height:map-get($line_heights, _small);
				font-size:map-get($font_sizes, _small);
				background:map-get($colors, _01-04);

				@media screen and (min-width:1221px)
				{
					padding:48px;

					&:not(:first-child) {
						margin-top:96px;
					}
				}
				@media screen and (max-width:1220px) and (min-width:769px)
				{
					padding:32px;

					&:not(:first-child) {
						margin-top:48px;
					}
				}
				@media screen and (max-width:768px)
				{
					@include relative;
				}
			}
				.timeline-item_thumb {
					padding-bottom:24px;
				}

				.timeline-item_content {

					@media screen and (max-width:768px)
					{
						padding:0 24px 24px;
					}
				}
					.timeline-item_heading {

						@media screen and (min-width:769px)
						{
							@include relative;
						}

						&:before,
						&:after {
							@include absolute;
							content:"";
							top:50%; right:-99px;
							transform:translateY(-50%);
							background:map-get($colors, _02-01);

							@media screen and (max-width:1220px) and (min-width:769px)
							{
								right:-83px;
							}
							@media screen and (max-width:768px)
							{
								left:50%; right:auto;
								transform:translateX(-50%) rotate(90deg);
							}
						}
						&:before {
							width:6px; height:48px;

							@media screen and (max-width:768px)
							{
								top:-24px;
							}

						}
						&:after {
							width:18px; height:6px;

							@media screen and (max-width:768px)
							{
								top:3px;
							}
						}
					}
