/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field {
	padding-top:24px;

	@media screen and (max-width:768px)
	{
		padding-top:18px;
	}
}
	.field-label {
		@include flex-row;
		padding-bottom:6px;
        line-height:map-get($line_heights, _base);
		color:map-get($colors, _01-01);
        font-size:16px;

		em {
			margin-left:auto; padding-left:24px;
			color:map-get($colors, _01-02);
			font-weight:400;
			text-transform:none;
		}
	}


/*
 * Form Light Modifier
 */

.form-light {

    .field-label {
        color:#FFF;

        em {
            color:rgba(#FFF, .64);
        }
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD FEEDBACK --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field-feedback {
	@include animate;
	@include relative;
	max-height:0;
	overflow:hidden;
	opacity:0;

	span {
		display:block;
		padding-top:12px;
		line-height:map-get($line_heights, _small);
		font-size:map-get($font_sizes, _small);
		color:map-get($colors, _error);
	}
}


/*
 * Form Light Modifier
 */

.form-light {

    .field-feedback span {
        color:rgba(#FFF, .64);
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD BADGES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.badge-valid,
.badge-invalid {
	@include animate;
	@include flex-column;
	justify-content:center;
	align-items:center;
	position:absolute;
	right:-13px; top:-13px;
	z-index:2;
	width:24px; height:24px;
	border-radius:100%;
	opacity:0;

	svg {
		display:block;
		width:12px; height:12px;
		fill:#FFF;
	}
}
.badge-valid {
	background:map-get($colors, _valid);
}
.badge-invalid {
	background:map-get($colors, _error);
}

.input-radio,
.input-checkbox {

	.badge-valid,
	.badge-invalid {
		top:0; left:26px; right:auto;
		margin-top:-2px;
		width:8px; height:8px;

		@media screen and (max-width:768px)
		{
			left:27px;
			margin-top:0;
		}

		svg {
			display:none;
		}
	}
}

/* --- Valid --- */
.is-valid {

	.field-feedback,
	.badge-invalid {
		opacity:0;
	}
	.badge-valid {
		opacity:1;
	}
}

/* --- Invalid --- */
.is-invalid {

	.badge-valid {
		opacity:0;
	}
	.field-feedback,
	.badge-invalid {
		opacity:1;
	}

	.field-feedback {
		max-height:none;
	}
}


/*
 * Form Light Modifier
 */

.form-light {

    .badge-valid,
    .badge-invalid {
    	background:#FFF;
    }
    .badge-valid svg {
        fill:map-get($colors, _valid);
    }
    .badge-invalid svg {
    	fill:map-get($colors, _error);
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT TEXT / TEXTAREA -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.input-text {
	@include animate;
	@include relative;
	border:2px solid rgba(map-get($colors, _01-01), .12);

	input,
	textarea {
		@include animate;
		margin:0; padding:16px 24px;
		width:100%; height:auto;
		line-height:map-get($line_heights, _base);
		color:map-get($colors, _01-01);
		font-family:map-get($font_families, _01);
		font-size:100%; font-size:16px;
		box-sizing:border-box;
		appearance:none;

		@media screen and (max-width:768px)
		{
			padding:12px 24px;
		}
	}
	input {

		&:not([value=""]) + .label_in-field,
		&:focus + .label_in-field {
			z-index:1;
			padding:9px 24px;
			font-size:8px;
			opacity:.48;
		}

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				position:relative;
				z-index:2;
			}
		}

		&:disabled {
			color:rgba(map-get($colors, _01-01), .24);
			cursor:default;
		}
	}

	.label_in-field {
		@include animate;
		@include full-size;
		padding:16px 24px;
		line-height:map-get($line_heights, _small);
		color:map-get($colors, _01-01);
		font-size:16px;
		box-sizing:border-box;
		cursor:pointer;

        @media screen and (max-width:768px)
		{
			padding:12px 24px;
		}
	}
}


/*
 * Form Light Modifier
 */

.form-light {

    .input-text {
    	border-color:#FFF;

        input,
    	textarea,
        .label_in-field {
    		color:#FFF;
    	}
        input:disabled {
			color:rgba(#FFF, .24);
    	}
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field-select {
	@include relative;

	select {
		@include animate;
		@include relative;
		margin:0; padding:16px 34px 16px 0;
		width:100%; height:auto;
		line-height:map-get($line_heights, _base);
		color:map-get($colors, _01-01);
		font-family:map-get($font_families, _01);
		font-size:100%; font-size:16px;
		box-sizing:border-box;
		background:none;
		border-bottom:1px solid rgba(map-get($colors, _01-01), .12);
		appearance:none;

		@media screen and (max-width:768px)
		{
			padding:12px 34px 12px 0;
		}

		&:disabled {
			color:map-get($colors, _01-01);
			cursor:default;

			& + .chevron {
				opacity:.24;
			}
		}
	}

	.chevron {
		@include animate;
		position:absolute;
		top:50%; right:24px;
		z-index:2;
		margin-top:-6px;
		width:12px; height:12px;
		transform:rotate(90deg);

		svg {
			display:block;
			width:12px; height:12px;
			fill:map-get($colors, _01-01);
		}
	}
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT RADIO / CHECKBOX ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.input-radio,
.input-checkbox {
	@include animate;
	@include relative;
	@include flex-row;
	align-items:center;

	input {
		position:absolute;
		top:50%; left:0;
		z-index:1;
		margin:0; padding:0;
		width:32px; height:32px;
		line-height:1;
		color:map-get($colors, _01-01);
		font-size:100%; font-size:16px;
		box-sizing:border-box;
		background:none;
		border:0 solid;
		border-radius:0;
		opacity:0;
		cursor:pointer;
		appearance:none;
		transform:translateY(-50%);

		&:checked:not(:disabled) + span,
		&:checked:not(:disabled) + span {
			background:map-get($colors, _02-02);
			border:2px solid map-get($colors, _02-02);

			&:after,
			svg {
				opacity:1;
			}
		}

		&:disabled {
			cursor:default;

			& + span + label {
				opacity:.24;
				cursor:default;
			}
		}
	}

	input + span {
		@include animate;
		@include flex-column;
		justify-content:center;
		align-items:center;
		flex-shrink:0;
		width:32px; height:32px;
		border:2px solid rgba(map-get($colors, _01-01), .12);
		box-sizing:border-box;

		&:after,
		svg {
			@include animate;
			display:block;
			opacity:0;
		}
	}

	input + span + label {
		flex:1 1 auto;
		flex-direction:column;
		margin:0; padding:0 0 0 18px;
		line-height:1.2;
        font-size:16px;
		text-align:left;
		cursor:pointer;
	}

	& + .field-feedback:before {
		left:6px;
	}
}

.input-radio {

	input + span {
		border-radius:100%;

		&:after {
			content:"";
			width:8px; height:8px;
			background:map-get($colors, _01-01);
			border-radius:100%;
		}
	}
}

.input-checkbox {

	input + span {

		svg {
			width:16px; height:16px;
			fill:#FFF;
		}
	}
}


/*
 * Form Light Modifier
 */

.form-light {

    .input-radio,
    .input-checkbox {

        input + span {
    		border-color:#FFF;
    	}
    }
    .input-radio input + span:after {
    	background:#FFF;
    }

    .input-checkbox input + span svg {
    	fill:#FFF;
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE RECAPTCHA ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.g-recaptcha {
	display:none;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.form-wrapper {
}
	.form {
		margin-top:-24px;

		@media screen and (max-width:768px)
		{
			margin-top:-18px;
		}

		a {
			@include animate;
			text-decoration:underline;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:map-get($colors, _01-01);
					text-decoration:none;
				}
			}
		}
	}
		.form-btn_wrapper {
			@include flex-row;
			align-items:flex-end;
			padding-top:48px;

            @media screen and (max-width:768px)
            {
                padding-top:24px;
			}

			.btn {
				width:100%;
			}
		}
		.form-bottom {
			padding-top:48px;

            @media screen and (max-width:768px)
            {
                padding-top:24px;
            }
		}
