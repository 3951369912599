/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE HEADING ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.page-heading {

    &.align-center {
        text-align:center;

        .page-preheading,
        .page-title,
        .page-subheading {
            margin:0 auto;
        }
    }
}
    .page-preheading {
        color:map-get($colors, _01-01);
        font-family:map-get($font_families, _01);
        font-size:map-get($font_sizes, _base);
        font-weight:500;
    }
    .page-title {
        color:map-get($colors, _02-01);

        &:not(:first-child) {
            padding-top:24px;
        }

        @media screen and (max-width:768px)
        {
            padding-top:24px;
            text-align:left;
        }
    }
    .page-subheading {
        padding-top:24px;
        max-width:map-get($widths, _small);

        @media screen and (max-width:768px)
        {
            padding-top:18px;
            text-align:left;
        }
    }
