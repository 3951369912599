/* ------------------------------------------------------------------------------------------------------------------------ */
/* DOCUMENT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

* {
	margin:0; padding:0;
	font-size:100%;
	vertical-align:baseline;
    outline:none;
}

html {
	text-size-adjust:100%;
	-webkit-font-smoothing:antialiased;
	background-color:rgba(map-get($colors, _01-04), .48);
}

body {
	margin:0; padding:0;
	line-height:map-get($line_heights, _base);
	color:map-get($colors, _01-01);
	font-family:map-get($font_families, _01);
	font-size:map-get($font_sizes, _base);
	font-weight:400;
	cursor:default;
	overflow-x:auto; overflow-y:scroll;

	@media screen and (max-width:768px)
	{
		line-height:map-get($line_heights, _small);
		font-size:map-get($font_sizes, _small);
	}
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SECTIONS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
	display:block;
}

audio, canvas, video {
	display:inline-block;
	*display:inline;
	*zoom:1;
}
audio:not([controls]) { display:none; }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECTIONS ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

::selection {
	color:#FFF;
	background-color:map-get($colors, _02-01);
}
::-moz-selection {
	color:#FFF;
	background-color:map-get($colors, _02-01);
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOCUS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin focus {
    outline:2px solid #FFF;
    outline-offset:2px;

    body:not(.has-tabbing-user) & {
        outline:none;
    }
}

:focus {
    @include focus;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* GTM NOSCRIPT ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.gtm-noscript {
	display:none;
	width:0; height:0;
	visibility:hidden;
}
