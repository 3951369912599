/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.footer {
    color:rgba(#FFF, .64);
    line-height:1.4;
    font-size:14px;
    text-align:center;
    background:map-get($colors, _01-01);

    a:not(.link) {
        @include animate;
        color:rgba(#FFF, .64);
        text-decoration:none;

        @media screen and (min-width:769px)
        {
            &:hover,
            &:focus {
                color:#FFF;
            }
        }
    }
}


/*
 * Socials
 */

.footer-socials {
    @include flex-column;
    align-items:center;
    padding-top:0;
    padding-bottom:24px;
}
    .footer-socials_list {
        @include flex-row;
        list-style:none;
    }
        .footer-socials_item {
            @include flex-column;
            justify-content:center;
            align-items:center;

            &:not(:last-child) {
                padding-right:24px;
            }
        }
            .footer-socials_link {
                width:24px; height:24px;

                svg {
                    @include animate;
                    display:block;
                    width:18px; height:18px;
                    fill:#FFF;
                }

                @media screen and (min-width:769px)
				{
                    @include flex-column;
                    justify-content:center;
                    align-items:center;

					&:hover svg,
					&:focus svg {
						fill:map-get($colors, _02-03);
					}
                }
                @media screen and (max-width:768px)
                {
                    width:18px; height:18px;
                }
            }
