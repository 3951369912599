/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$colors: (
    _white: #FFF,
    _01-01: #0E0E10, // Black(ish)
    _01-02: #605F6B, // Grey
    _01-03: #908D9B, // Grey (Lighter)
    _01-04: #EFEEF1, // Grey (Lightest)
    _02-01: #10354E, // Blue
    _02-02: #6295BE, // Light-blue
    _02-03: #9BCDF8, // Light-blue (Lighter)
    _error: #D91E18,
    _alert: #F89406,
    _valid: #00B16A,
    _update: #1E8BC3
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$widths: (
    _large: 1440px,
    _base: 1220px,
    _medium: 980px,
    _medium_small: 780px,
    _small: 680px,
    _xsmall: 480px
);

$header_height: (
    _desktop: 112px,
    _mobile: 80px
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$line_heights: (
    _base: 1.6,
    _small: 1.5
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$font_sizes: (
    _base: 18px,
    _small: 16px
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* Inter 400 (Regular) */
@font-face {
    font-family:'Inter';
    font-style:normal;
    font-weight:400;
    font-display:swap;
    src:url('../fonts/inter/Inter-Regular.woff2?v=3.15') format('woff2'),
        url('../fonts/inter/Inter-Regular.woff?v=3.15') format('woff');
}

/* Inter 500 (Medium) */
@font-face {
    font-family:'Inter';
    font-style:normal;
    font-weight:500;
    font-display:swap;
    src:url('../fonts/inter/Inter-Medium.woff2?v=3.15') format('woff2'),
        url('../fonts/inter/Inter-Medium.woff?v=3.15') format('woff');
}

/* Inter 700 (Bold) */
@font-face {
    font-family:'Inter';
    font-style:normal;
    font-weight:700;
    font-display:swap;
    src:url('../fonts/inter/Inter-Bold.woff2?v=3.15') format('woff2'),
        url('../fonts/inter/Inter-Bold.woff?v=3.15') format('woff');
}

/* Frank Ruhl Libre 400 (Regular) */
@font-face {
    font-family:'Frank Ruhl Libre';
    font-style:normal;
    font-weight:400;
    src:url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.eot');
    src:local('Frank Ruhl Libre'), local('FrankRuhlLibre-Regular'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.woff2') format('woff2'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.woff') format('woff'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.ttf') format('truetype'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.svg#FrankRuhlLibre') format('svg');
}

/* Frank Ruhl Libre 500 (Medium) */
@font-face {
    font-family:'Frank Ruhl Libre';
    font-style:normal;
    font-weight:500;
    src:url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.eot');
    src:local('Frank Ruhl Libre Medium'), local('FrankRuhlLibre-Medium'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.eot?#iefix') format('embedded-opentype'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.woff2') format('woff2'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.woff') format('woff'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.ttf') format('truetype'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.svg#FrankRuhlLibre') format('svg');
}

/* Frank Ruhl Libre 700 (Bold) */
@font-face {
    font-family:'Frank Ruhl Libre';
    font-style:normal;
    font-weight:700;
    src:url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.eot');
    src:local('Frank Ruhl Libre Bold'), local('FrankRuhlLibre-Bold'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.woff2') format('woff2'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.woff') format('woff'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.ttf') format('truetype'),
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.svg#FrankRuhlLibre') format('svg');
}

$font_families: (
    _01: ('Inter', sans-serif),
    _02: ('Frank Ruhl Libre', sans-serif)
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1:unit($min-vw);
	$u2:unit($max-vw);
	$u3:unit($min-font-size);
	$u4:unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size:$min-font-size;
			@media screen and (min-width:$min-vw) {
				font-size:calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width:$max-vw) {
				font-size:$max-font-size;
			}
		}
	}
}
