/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.hero {
	background:#F7F7F7;

	@media screen and (min-width:769px)
	{
		min-height:calc(100vh - #{map-get($header_height, _desktop)});
	}
	@media screen and (max-width:768px)
	{
		@include relative;
	}
}

	.hero-lhc {
		@include relative;
		flex:1 1 auto;

		@media screen and (max-width:768px)
		{
			@include full-size;
		}

		&:before {
    		@include relative;
    		@include figure_aspect-ratio('1 / 1');
    	}
	}
		.hero-image_wrapper,
		.swiper-container,
		.hero-image {
			@include full-size(2);
		}
		.hero-image {
			position:absolute !important;
			height:100% !important;
			object-fit:cover; font-family:'object-fit:cover;';
			// filter:grayscale(100%);
		}
		.hero-prev,
		.hero-next {
			color: #ffffff;
			@media screen and (max-width:768px)
			{
				display: none;
			}
		}
		.hero-prev:after {
			content: '';
		}
		.hero-next:after {
			content: '';
		}

	.hero-rhc {
		@include flex-column;
		justify-content:center;
		flex:1 1 auto;
		width:100%;
		box-sizing:content-box;

		@media screen and (min-width:769px)
		{
			max-width:429px;
		}
		@media screen and (max-width:768px)
		{
			@include relative(2);
			justify-content:flex-end;
			padding-right:48px;
			padding-bottom: 24px;
			min-height:calc(100vh - #{map-get($header_height, _mobile)});
			color:#FFF;
			background: rgba(0,0,0,0.25);
			background: linear-gradient(360deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.25) 100%);
			box-sizing:border-box;
		}
	}
		.hero-preheading {
			font-family:map-get($font_families, _01);
			font-size:map-get($font_sizes, _base);
			font-weight:500;
		}
		.hero-heading {
			color:map-get($colors, _02-01);
			font-size:48px;
			font-weight:500;

			@media screen and (max-width:768px)
			{
				line-height:1.2;
				color:#FFF;
				font-size:48px;
			}

			&:not(:first-child) {
				padding-top:24px;
			}
		}
		.hero-text {

			@media screen and (max-width:768px)
			{
				color:#FFF !important;
			}

			&:not(:first-child) {
				padding-top:24px;
			}
		}
		.hero-cta_wrapper {

			@media screen and (max-width:768px)
			{
				flex-direction:row !important;
			}

			&:not(:first-child) {
				padding-top:48px;
			}
		}
