/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.page-builder {
}


/*
 * Block
 */

.pb-block {
    @include relative;
	padding:96px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}

	&.half-padding-top {
		padding-top: 48px;
	}

	&.reset-padding-bottom {
		padding-bottom: 0;
	}

	// Background
	&.pb-block_has-bg {
		@include relative;
		justify-content:center;

        &.bg-type_image {
            @media screen and (min-width:769px)
            {
                min-height:map-get($widths, _xsmall);
            }
        }
	}

	// No Background
	&:not(.pb-block_has-bg) {

		& + .pb-block:not(.pb-block_has-bg) {
			margin-top:-96px !important;

			@media screen and (max-width:1220px) and (min-width:769px)
			{
				margin-top:-48px !important;
			}
			@media screen and (max-width:768px)
			{
				margin-top:-24px !important;
			}
		}
	}
}
	.pb-block_content_wrapper {
		@include relative;
		margin:0 -96px; padding:0 96px;
		width:calc(100% + 192px);
		box-sizing:border-box;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			margin:0 -48px; padding:0 48px;
			width:calc(100% + 96px);
		}
		@media screen and (max-width:768px)
		{
			margin:0 -24px; padding:0 24px;
			width:calc(100% + 48px);
		}
	}


/*
 * Background
 */

.pb-block_background {
	@include full-size(-1);
	overflow:hidden;
}
	.pb-block_background_image-wrapper,
	.pb-block_background_image {
		@include full-size;
	}
	.pb-block_background_image {
		position:absolute !important;
		height:100% !important;
		object-fit:cover; font-family:'object-fit:cover;';
	}


/*
 * Intro
 */

.pb-block_intro {
	text-align:center;
	@media screen and (max-width:768px)
	{
		text-align: left;
	}

	& + .pb-block_content_wrapper,
	& + .pb-block_content {
		padding-top:48px;

        @media screen and (max-width:768px)
    	{
            padding-top:24px;
        }
	}
}


/*
 * Calls to Action
 */

.pb-block_cta-wrapper {
	@include flex-row;

	@media screen and (min-width:769px)
	{
		margin-top:auto;
	}

    &:first-child {

        .btn {
            width:100%;
            text-align:center;
        }
    }
    &:not(:first-child) {
        padding-top:24px;

        @media screen and (max-width:768px)
        {
            padding-top:24px;
			padding-bottom: 24px;
        }
    }
}
