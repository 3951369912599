/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.page-heading + .archive-wrapper,
.page-builder + .archive-wrapper {

	@media screen and (min-width:1221px)
	{
		margin-top:-48px;
	}
}

.archive-grid {
	margin-top:-48px;

	@media screen and (max-width:768px)
	{
		margin-top:-24px;
	}
}
	.archive-grid_column {
		padding-top:48px;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			width:50% !important;
		}
		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}
		.archive-slide {
			@include flex-column;
			height:auto !important;
		}


/*
 * Article Card
 */

.article-card_content {

	@media screen and (min-width:769px)
	{
		flex-direction:row !important;
	}
}

	.article-date {
		text-align:center;

		@media screen and (min-width:769px)
		{
			margin-right:24px; padding-right:24px;
			width:100%; max-width:48px;
			border-right:1px solid map-get($colors, _01-04);
		}
		@media screen and (max-width:768px)
		{
			@include absolute(3);
			left:0; bottom:0;
			padding:16px 24px;
			line-height:1.2;
			color:#FFF;
			background:map-get($colors, _02-02);
		}
	}
		.article-date_number {
			font-size:24px;
			font-weight:700;

			@media screen and (max-width:768px)
			{
				font-size:map-get($font_sizes, _base);
			}
		}
		.article-date_month {
			font-weight:500;

			@media screen and (max-width:768px)
			{
				font-size:14px;
			}
		}

	.article-card_rhc {
		@include flex-column;
	}
		.card-link_wrapper {
			margin-top:auto;
		}
