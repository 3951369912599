/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (ARCHIVE) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pgn-archive {
	@include flex-row;
	justify-content:space-between;
	align-items:center;

	@media screen and (max-width:768px)
	{
		margin:24px -24px -24px; padding:0;
		width:calc(100% + 48px);
		border-top:1px solid map-get($colors, _01-04);
	}
}

	.pgn-archive_list {
		@include flex-row;
		justify-content:center;
		align-items:center;
		width:100%;
		list-style:none;

		@media screen and (max-width:768px)
		{
			justify-content:space-between;
		}
	}
		.pgn-archive_list-item {
			margin:0 6px;
			font-weight:500;
		}
			.pgn-archive_list-item_link {
				@include animate;
				@include flex-column;
				justify-content:center;
				align-items:center;
			}

	.pgn-archive_btn {
		@include animate;
		flex-shrink:0;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:map-get($colors, _01-01) !important;
			}
		}
	}

// Page
.pgn-archive_page {
	@include flex-column;
	justify-content:center;

	.pgn-archive_list-item_link {
		padding:12px 18px;
		line-height:1;
		color:map-get($colors, _01-01);
		background:transparent;
		border:2px solid map-get($colors, _01-04);
	}

	&.is-current .pgn-archive_list-item_link {
		color:#FFF;
		background:map-get($colors, _02-02);
		border-color:map-get($colors, _02-02);
	}

	@media screen and (min-width:769px)
	{
		&:not(.is-current):hover .pgn-archive_list-item_link,
		&:not(.is-current):focus .pgn-archive_list-item_link {
			color:map-get($colors, _02-02);
			background:map-get($colors, _01-04);
		}
	}
	@media screen and (max-width:768px)
	{
		display:none;
	}
}

// Prev / Next
.pgn-archive_prev,
.pgn-archive_next {

	.pgn-archive_list-item_link {
		@include animate;
		padding:12px;

		svg {
			@include animate;
			display:block;
			width:18px; height:18px;
			fill:map-get($colors, _02-02);
		}
	}

	&.is-disabled .pgn-archive_list-item_link svg {
		fill:rgba(map-get($colors, _01-01), .24);
	}

	@media screen and (min-width:769px)
	{
		&:not(.is-disabled) .pgn-archive_list-item_link:hover svg,
		&:not(.is-disabled) .pgn-archive_list-item_link:hover svg {
			fill:map-get($colors, _02-01);
		}
	}
	@media screen and (max-width:768px)
	{
		margin:0; padding:24px;
	}
}

// Prev
.pgn-archive_prev {

	@media screen and (min-width:769px)
	{
		padding-right:48px;
	}
	@media screen and (max-width:768px)
	{
		border-right:1px solid map-get($colors, _01-04);
	}

	.pgn-archive_list-item_link svg {
		transform:rotate(-180deg);
	}
}

// Next
.pgn-archive_next {

	@media screen and (min-width:769px)
	{
		padding-left:48px;
	}
	@media screen and (max-width:768px)
	{
		border-left:1px solid map-get($colors, _01-04);
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (SLIDER) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pgn-slider {
	@include flex-row;
	justify-content:center;
	align-items:center;

	.swiper-pagination-bullet {
		width:12px; height:12px;

		&:not(:last-child) {
			margin-right:8px;
		}

		&:only-child {
			display:none;
		}
	}

	// Dark BG
	&.style-dark {

		.swiper-pagination-bullet {
			background:#FFF;

			&.swiper-pagination-bullet-active {
				background:map-get($colors, _bell-coral);
			}
		}
	}
}
