.pb_block_grid_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width:768px)
    {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    & > div {
        flex: 0 50%;
        @media screen and (max-width:768px)
        {
            flex: 0 100%;
        }
    }
}
.pb_block_grid_item {
    width: 100%;
    margin-bottom: 12px;

    img {
        width: 100%;
    }

    .grid_img_container {
        margin: 24px;
        img {
            border-radius: 100%;
        }
        .grow {
            transition: all 1s ease-in-out;
            opacity: 0.7;
            &:hover {
                transform: scale(1.1);
                opacity: 1;
            }
        }
    }

    h4 {
        padding: 16px 0;

        a {
            color: map-get($colors, _02-01);
            display: block;
        }
    }

    .block {
        display: block;
    }

    .center {
        text-align: center;
    }

    p {
        padding: 12px;
        color: map-get($colors, _01-02);
        a {
            color: map-get($colors, _01-02);
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    ul {
        padding: 12px 12px 12px 18px;
        color: map-get($colors, _01-02);
    }

    ul li {
        padding-bottom: 0.5em;
        &::before {
            content: "";
            color: map-get($colors, _01-02);
        }
    }

    .pb-block_cta-wrapper {
        padding-top: 0;
    }



}
