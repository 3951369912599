/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.header {
    position:fixed;
    top:0; left:0; right:0;
    z-index:99;
    padding:0 48px;
    color:#FFF;
    background:map-get($colors, _02-01);

    @media screen and (max-width:768px)
    {
        padding:0 24px;
    }

    &:after {
		@include animate;
		@include absolute(-1);
		content:"";
		bottom:-12px; left:0; right:0;
		height:12px;
		background:linear-gradient(to bottom, rgba(#000, .06) 0%, rgba(#000, 0) 100%);
		opacity:0;
	}
}
    .header-content {
        @include flex-row;
        justify-content:space-between;
        align-items:center;
        padding:24px 0;

        @media screen and (max-width:768px)
        {
            padding:16px 0;
        }
    }


/*
 * Logo
 */

.header-logo {
    flex-shrink:0;
    height:64px;

    @media screen and (min-width:769px)
    {
        padding-right:64px;
    }
    @media screen and (max-width:768px)
    {
        height:48px;
    }
}
    .logo-figure {
        display:block;
        width:auto !important; height:64px !important;

        @media screen and (max-width:768px)
        {
            height:48px !important;
        }
    }


/*
 * Header (Trigger)
 */

.header-trigger {
    flex-shrink:0;
    width:32px;
    height:32px;

    svg {
        display:block;
        width:32px;
        height:32px;
        fill:#FFF;
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* IS STICKY HEADER ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.is-sticky-header {

    .header:after {
		opacity:1;
	}
}
